import { Component, computed, Input, input } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';

@Component({
  selector: 'dojo-markdown-viewer',
  template: `
    <markdown [data]="contentWithFixedContent()" katex ngPreserveWhitespaces [class.prose]="applyProseClass" />
  `,
  styles: `
    :host ::ng-deep markdown {
      /*render specific UI for different types of markdown blocks*/
      .example-block {
        border-radius: 1rem;
        padding: theme('spacing.4');
        border: 1px solid;
        font-size: theme('fontSize.sm.0');
        line-height: theme('fontSize.sm.1');
        margin-bottom: theme('spacing.3');

        > div {
          &::before {
            display: flex;
            --icon-size: theme('spacing.4');
            padding-left: calc(#{var(--icon-size)} + theme('spacing.2'));
            background-size: var(--icon-size) var(--icon-size);
            background-repeat: no-repeat;
            background-position: left center;
            margin-bottom: theme('spacing.3');
            font-weight: 600;
            font-size: theme('fontSize.xs.0');
            line-height: theme('fontSize.xs.1');
          }
        }

        &.warning {
          border-color: theme('colors.yellow.300');
          background: theme('colors.yellow.50');
          color: theme('colors.yellow.700');

          > div {
            &::before {
              content: 'Warning';
              background-image: url('/assets/images/callout/warning-icon.svg');
            }
          }
        }

        &.note {
          border-color: theme('colors.sky.300');
          background: theme('colors.sky.50');
          color: theme('colors.sky.700');

          > div {
            &::before {
              content: 'Note';
              background-image: url('/assets/images/callout/note-icon.svg');
            }
          }
        }

        &.tip {
          border-color: theme('colors.purple.300');
          background: theme('colors.purple.50');
          color: theme('colors.purple.700');

          > div {
            &::before {
              content: 'Tip';
              background-image: url('/assets/images/callout/tip-icon.svg');
            }
          }
        }

        &.example {
          border-color: theme('colors.green.300');
          background: theme('colors.green.50');
          color: theme('colors.green.700');

          > div {
            &::before {
              content: 'Example';
              background-image: url('/assets/images/callout/example-icon.svg');
            }
          }
        }
      }
    }
  `,
  imports: [MarkdownModule],
  host: { class: 'max-w-full' },
})
export class MarkdownViewerComponent {
  readonly content = input.required<string>();
  readonly katexEnvsRegexp =
    /(\\begin\{(?:p?matrix|smallmatrix|vmatrix|Bmatrix|bmatrix|aligned|cases)\}[\s\S]*?\\end\{(?:p?matrix|smallmatrix|vmatrix|Bmatrix|bmatrix|aligned|cases)\})/g;

  readonly contentWithFixedContent = computed(() => {
    const content = this.content();

    // https://linear.app/tm007/issue/FE-504
    const fixedLatexContent = content.replace(/\$(.*?)\$/gs, (match, p1) => `$${p1.replaceAll('\\%', '\\\\%')}$`);

    // https://linear.app/tm007/issue/FE-381
    return fixedLatexContent.replaceAll(
      this.katexEnvsRegexp,
      // Replace 2 backslashes with 4 backslashes for the matrix line separation.
      (match, p1) => `${p1.replaceAll('\\\\', '\\\\\\\\')}`,
    );
  });
  @Input() applyProseClass?: boolean = true;
}
